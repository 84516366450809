.nomatch__container {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-top: 15%;
    }
    h3 {
      a {
        color: #1c9854;
      }
      a:hover {
        color: #FF9F43;
      }
    }
  }